
/* .homePageContainer{
    background-image: url("../images/wallpapers/wall3.jpg");
} */

.body{
    max-width: 1500px;
    margin: auto;
    margin-top: 15px;
    width:95%;
}
.contactLine{
    box-sizing: border-box;
    padding: 0% 5% 0% 5%;
    display: flex;
    justify-content: space-around;
    line-height: 3px;
    text-align: center;
    margin: auto;
    background-color: #00438b;
    color: white;
    width:100%;
    margin-top: 110px;
}
.contactLineLinkTag{
    text-decoration: none;
    color: white;
}

.companyNameDoctor{
    max-width: 2900px;
    padding: 0px 5px;
    background-color: #c9d7f2;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 485px;
}
.companyName{
    font-family: "Gill Sans", sans-serif;
    /* font-family: cursive; */
    text-align: center;
    width: 50%;
    background-color: #c9d7f2;
    color: #00438b;
}
.companyName>h1:nth-child(1){
margin-top: 40px;
font-size: 30px;

}
.companyName>h4{
    font-size: 22px;
    line-height: 1px;
    color: #007aff;
}
.companyName>hr{
  
    background-color: aliceblue;
    width: 50%;
    color: #007aff;
}
.companyName>h4:nth-child(2){
    margin-top: 50px;
    }
.doctorPhotoDiv{
    width: 50%;
    height: auto;
}
.doctor1{
    
    width: 100%;
    height: 100%;
}



.popularProductList{
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 5px;
    margin-bottom: 30px;
    color: #011c46;
    
}
.popularProductListInner{
    margin-top: 2%;
    box-sizing: border-box;
    display: flex;
    gap: 3%;
   
}
.popularProductCards{
    margin: auto;
    padding-left: 3%;
    padding-right: 3%;
    box-sizing: border-box;
    width: 37%;
}
.popularProductImg{ 
    height: 250px;
    /* box-sizing: border-box;
    width: 100%;
    height: 100%; */
} 
.popularProductImg img{ 
    width: 100%; 
    height: 100%; 
} 
.popularProductListButtons{

    text-align: center;
}
.popularProductListInquery{
    cursor: pointer;
    border: solid transparent;
    padding: 4px 12px;
    border-bottom-right-radius:20px;
    font-size: 20px;
    color: white;
    background-color: #011c46;
}
.popularProductListInquery:hover{
    background-color: #222831;
}

.popularProductView{
    cursor: pointer;
    margin-left: 5%;
    border: solid transparent;
    padding: 4px 12px;
    border-bottom-right-radius:20px;
    font-size: 20px;
    color: white;
    background-color: #007aff;
}
.popularProductView:hover{
    background-color: #00438b;
}

.popularProductViewMoreBtn{

    margin-bottom: 15px;
    margin-left: 79%;
    margin-top:40px;
    background-color:#00438b;
    color:white;
    padding:0px 15px;
    border-bottom-right-radius:31px;
    border:solid transparent;
    cursor:pointer;
    /* border-bottom-right-radius: 35px; */
}
.popularProductViewMoreBtn:hover{
    background-color: #007aff;
    
}


.aboutCompanyHome{
    line-height: 23px;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding: 2% 6%;
    text-align: center;
    background-color: #00438b;
    color: white;
}

.aboutCompanyHomeBTN{
    font-family: Roboto;
    font-size: 16px;
    margin-top: 25px;
    border-radius: 10px;
    border: solid transparent;
    color: #007aff;
    background-color: white;
    padding: 0px 3.3%;
}
.aboutCompanyHomeBTN:hover{
    color: white;
    cursor: pointer;
    background-color: #007aff;
    transition: 0.5s;
}


.imgline1{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(3,1fr);
    margin-top: 50px;
    
}
.commonImg{
    /* border: solid yellow; */
    width: 100%;
    height: 300px;
}

.homePageContact{
    padding: 10px 10px 30px 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-image: url("../images/wallpapers/wall3.jpg");
    margin-top: 30px;
    height: 300px;
    display: flex;
    /* border: solid yellow; */
    font-family: Roboto;
    color: #00438b;
}

.homePageContactTagDiv{
    width: 50%;
    padding: 50px 50px 20px 30px;
    box-sizing: border-box;
    background-image: url("../images/wallpapers/wall3.jpg");
    /* border: solid red; */
}
.homePageContactFormDiv{
    padding: 2px 2px 9px 2px; 
    background-color: transparent;
    margin: auto;
}

.homePageContactFormDiv input:nth-child(1){
    border: transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 35%;
    height: 35px;
    font-size: 15px;
    align-items: center;
    margin-right: 2%; 
}
.homePageContactFormDiv input:nth-child(2){
    border: transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 35%;
    height: 35px;
    font-size: 15px;
    align-items: center;
    margin: 10px auto; 
}

.homePageContactFormDiv input:nth-child(4){
    border: transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 35%;
    height: 35px;
    font-size: 15px;
    align-items: center;
    margin: 10px auto; 
    margin-right: 2%;
}
.homePageContactFormDiv input:nth-child(5){
    border: transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 35%;
    height: 35px;
    font-size: 15px;
    align-items: center;
    margin: 10px auto; 
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.message{
    resize: none;
    border: transparent ;
    border-bottom: solid #00438b;
    margin-top: 10px;
    font-size: 18px;
    padding: 5px 15px;
    width: 90%;
    text-align: left;
}
.contactbtn{
    border: solid transparent;
    color: white;
    background-color: #00438b;
    padding: 6px 20px;
    margin-top: 10px;
    font-size: 15px;
    width: 150px;
    border-radius: 6px;   
}
.contactbtn:hover{
    cursor: pointer;
    transition: 0.5s;
    background-color:#011c46
}

@media only screen and (min-width: 570px) and (max-width: 800px) {
    .contactLine{
        font-size: 11px;
        line-height: 19px;
        margin-top: 110px;
    }
    
    .companyNameDoctor{
        max-width: 2900px;
        padding: 0px 5px;
        background-color: #c9d7f2;
        margin: auto;
        display: flex;
        justify-content: space-between;
        height: 425px;
    }
    .companyName{
        font-family: "Gill Sans", sans-serif;
        /* font-family: cursive; */
        text-align: center;
        width: 50%;
        background-color: #c9d7f2;
        color: #00438b;
    }
    .companyName>h1:nth-child(1){
    margin-top: 40px;
    font-size: 25px;
    
    }
    .companyName>h4{
        font-size: 19px;
        line-height: 1px;
        color: #007aff;
    }
    .companyName>hr{
      
        background-color: aliceblue;
        width: 50%;
        color: #007aff;
    }
    .companyName>h4:nth-child(2){
        margin-top: 50px;
        }
    .doctorPhotoDiv{
        width: 75%;
        height: auto;
    }
    .doctor1{
        
        width: 100%;
        height: 100%;
    }
    
    
    
    .popularProductList{
        box-sizing: border-box;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin-top: 5px;
        margin-bottom: 30px;
        color: #011c46;
        
    }
    .popularProductListInner{
        margin-top: 2%;
        box-sizing: border-box;
        display: flex;
        gap: 3%;
       
    }
    .popularProductCards{
        margin: auto;
        padding-left: 3%;
        padding-right: 3%;
        box-sizing: border-box;
        width: 45%;
    }
    .popularProductImg{ 
        height: 220px;
        /* box-sizing: border-box;
        width: 100%;
        height: 100%; */
    } 
    .popularProductImg img{ 
        width: 100%; 
        height: 100%; 
    } 
    .popularProductListButtons{
    
        text-align: center;
    }
    .popularProductListInquery{
        cursor: pointer;
        border: solid transparent;
        padding: 4px 12px;
        border-bottom-right-radius:20px;
        font-size: 20px;
        color: white;
        background-color: #011c46;
    }
    .popularProductListInquery:hover{
        background-color: #222831;
    }
    
    .popularProductView{
        cursor: pointer;
        margin-left: 5%;
        border: solid transparent;
        padding: 4px 12px;
        border-bottom-right-radius:20px;
        font-size: 20px;
        color: white;
        background-color: #007aff;
    }
    .popularProductView:hover{
        background-color: #00438b;
    }
    
    .popularProductViewMoreBtn{
    
        margin-bottom: 15px;
        margin-left: 65%;
        margin-top:43px;
        background-color:#00438b;
        color:white;
        padding:0px 15px;
        border-bottom-right-radius:31px;
        border:solid transparent;
        cursor:pointer;
        /* border-bottom-right-radius: 35px; */
    }
    .popularProductViewMoreBtn:hover{
        background-color: #007aff;
        
    }
    .aboutCompanyHome{
        font-size: 12px;
        line-height: 25px;
    }

    .aboutCompanyHomeBTN{
        font-family: Roboto;
        font-size: 14px;
        margin-top: 25px;
        border-radius: 10px;
        border: solid transparent;
        color: #007aff;
        background-color: white;
        padding: 0px 2%;
        line-height: 10px;
    }

    .homePageContact{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 10px;
        background-image: url("../images/wallpapers/wall3.jpg");
        margin-top: 30px;
        height: auto;
        display: block;
        font-family: Roboto;
        color: #00438b;
    }
    .homePageContactTagDiv{
        font-size: 13px;
        width: 100%;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        background-image: url("../images/wallpapers/wall3.jpg");
    }
    .homePageContactFormDiv{
        padding: 2px 2px 9px 2px; 
        background-color: transparent;
        margin: auto;
    }
    
    .homePageContactFormDiv input:nth-child(1){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
        margin-right: 4%; 
    }
    .homePageContactFormDiv input:nth-child(4){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
        margin-right: 4%; 
    }
    .homePageContactFormDiv input:nth-child(5){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
    }
}

@media only screen and (min-width: 100px) and (max-width: 569px) {

    .body{
        margin: auto;
        margin-top: 8px;
        width:100%;
    }

    .contactLine{
        font-size: 10px;
        line-height: 19px;
        margin-top: 90px;
    }
    .companyNameDoctor{
        box-sizing: border-box;
        display: flex;
        margin: auto;
        justify-content: space-between;
        height: 220px;
    }
    .companyName{
        width: 60%;
        height: 100%;   
    }
    .companyName>h1:nth-child(1){
        margin-top: 8px;
        font-size: 108%;
    }
    .companyName>h4{
        font-size:95%;
        line-height: 5%;
        color: #007aff;
    }
    .companyName>h4:nth-child(2){
        margin-top: 20px;
    }
    .doctorPhotoDiv{
        overflow: hidden;
        /* border: solid red; */
        width: 50%;
        height: auto;
    }
    .doctor1{
        width: 100%; 
        height: 220px;
    }
    .commonImg{
        /* border: solid yellow; */
        width: 100%;
        height: 150px;
    }

    .popularProductList{
        margin: auto;
        margin-top: 15px;
    }
    .popularProductList>div :nth-child(1){
        font-size: 15px;
    }

    .popularProductListInner{
       
        box-sizing: border-box;
        display: block;
        
       
    }

    .popularProductCards{
        font-size: 13px;
        margin: auto;
        box-sizing: border-box;
        width: 80%;
        margin-bottom: 20px;
    }

    .popularProductImg{ 
        height: 150px;
    }
    .popularProductListInquery{
        font-size: 15px;
    }

    .popularProductView{
        font-size: 15px;
    }
    .popularProductViewMoreBtn{
       margin-left: 25%;
    }

    .aboutCompanyHomeBackground{
        margin: auto;
        width: 90%;
    }

    .aboutCompanyHome{
        
        font-size: 12px;
        line-height: 27px;
        font-family: Roboto;
        padding: 5% 6%;
        text-align: center;
        background-color: #007aff;
        color: white;
    }
    .aboutCompanyHome:first-child{
        background-color: #00438b;
    }
    .aboutCompanyHomeBTN{
        font-size: 10px;
        margin-top: 25px;
        border-radius: 10px;
        border: solid transparent;
        color: #007aff;
        background-color: white;
        padding: 0px 3.3%;
    }

    
   
    .imgline1{
        display: none;
    }

    .homePageContact{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 10px;
        background-image: url("../images/wallpapers/wall3.jpg");
        margin-top: 30px;
        height: auto;
        display: block;
        font-family: Roboto;
        color: #00438b;
    }
    .homePageContactTagDiv{
        font-size: 13px;
        width: 100%;
        padding: 10px 10px 10px 10px;
        box-sizing: border-box;
        background-image: url("../images/wallpapers/wall3.jpg");
    }
    .homePageContactFormDiv{
        padding: 2px 2px 9px 2px; 
        background-color: transparent;
        margin: auto;
    }
    
    .homePageContactFormDiv input:nth-child(1){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
        margin-right: 4%; 
    }
    .homePageContactFormDiv input:nth-child(2){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
        margin: 10px auto; 
    }
    
    .homePageContactFormDiv input:nth-child(4){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
        margin-right: 4%; 
    }
    .homePageContactFormDiv input:nth-child(5){
        border: transparent;
        border-bottom: solid #00438b;
        padding: 3px 10px;
        width: 35%;
        height: 28px;
        font-size: 13px;
        align-items: center;
        margin: 10px auto; 
    }

    .message{
        min-height: 100px;
        resize: none;
        border: transparent ;
        border-bottom: solid #00438b;
        margin-top: 10px;
        font-size: 15px;
        padding: 3px 10px;
        width: 90%;
        text-align: left;
    }












    .companyNameDoctor{
        /* border: solid red; */
        box-sizing: border-box;
        display: flex;
        margin: auto;
        justify-content: space-between;
        height: 220px;
        overflow: hidden;
    }
    .companyName{
        position: absolute;
        
        width: 42%;
        overflow: hidden;
        height: 220px;
        line-height: 16px;
    }
    .companyName>h1:nth-child(1){
        margin-top: 25px;
       
        font-size: 100%;
    }
    .companyName>h4{
        /* border: solid red; */
        line-height: 15px;
        font-size:95%;
        /* line-height: 5%; */
        color: #007aff;
        margin-top: 13px;
        margin-bottom: 1px;
    }
    .companyName>hr{
        padding: 0px;
        line-height: 0px;
        margin-top: 0px;
    }
    .companyName>h4:nth-child(6){
       line-height: 12px;
    }
    .companyName>h4:nth-child(8){
        line-height: 13px;
     }
     .doctorPhotoDiv{
       margin-left: 18%;
        width: 88%;
        height: 100%;
    }
}

@media only screen and (min-width: 100px) and (max-width: 317px){
    
    .companyNameDoctor{
        /* border: solid red; */
        box-sizing: border-box;
        display: flex;
        margin: auto;
        justify-content: space-between;
        height: 220px;
        overflow: hidden;
    }
    .companyName{
        position: absolute;
        /* border: solid yellow; */
        width: 40%;
        overflow: hidden;
        height: 220px;
        line-height: 16px;
    }
    .companyName>h1:nth-child(1){
        /* margin-top: 8px; */
        font-size: 100%;
    }
    .companyName>h4{
        /* border: solid red; */
        line-height: 15px;
        font-size:95%;
        /* line-height: 5%; */
        color: #007aff;
        margin-top: 0px;
        margin-bottom: 1px;
    }
    .companyName>hr{
        padding: 0px;
        line-height: 0px;
        margin-top: 0px;
    }
    .companyName>h4:nth-child(6){
       line-height: 12px;
    }
    .companyName>h4:nth-child(8){
        line-height: 13px;
     }
     .doctorPhotoDiv{
       margin-left: 12%;
        width: 88%;
        height: 100%;
    }
}