.footer{
    margin: auto;
    max-width: 1800px;
    /* border: solid yellow; */
    box-sizing: border-box;
    color: white;
    text-align: center;
    padding: 45px 45px;
    /* padding-bottom: 25px; */
    background-color: #1a191d;
    display: flex;
    justify-content:space-between;
    justify-items: center;
    width: 100%;
    margin-top: 30px;
}

.hrTag{
    width: 50%;
}
.footerright{
    /* border: solid red; */
    text-align: center;
    width: 100%;
    margin-left: 1%;
    display: flex;
    justify-content: space-around;
}


.footer1{
    color: white;
    padding-right: 30px;
    border-right: solid #3087e5;
    font-size: 17px;
}
.contactLinkTag{
    text-decoration: none;
    color: white;
}
.contactLinkTag:hover{
    color: #3087e5;
}

.footer1>div>img{
    border: solid transparent;
    /* width: 100px; */
    width: 45%;
    border-radius: 50%;
   
}


.footer2{
    /* border: solid yellow; */
    text-align: center;
    /* width: 20%; */
   box-sizing: border-box;
   
}

.footer3{
    width: 20%;
   
}
.footer4{
    width: 20%;
}
.linkTag{
    color: white;
    text-decoration: none;
}
.linkTag :hover{
    color: #3087e5;
    text-decoration: none;
}


@media only screen and (min-width: 100px) and (max-width: 610px)  {
    .footer{
        /* border: solid red; */
        margin: auto;
        box-sizing: border-box;
        padding: 10px 8px;
        width: 100%;
        display: block;
        justify-content:space-around;
    }
    .footer1{
        border: solid red;
        padding-top: 10px;
        padding-right: 0px;
        padding-bottom: 15px;
        border: solid transparent;
        border-bottom: solid #3087e5;
        text-align: center;
        width: 100%;
        display: flex;
    }

    .footer1ImgDiv{
        width: 50%;
        padding-left: 3%;
    }

    .footer1>div>img{
        border: solid transparent;
        /* width: 100px; */
        width: 45%;
        border-radius: 50%;    
    }
    .footer1 :nth-child(1){
        width: 70%;
    }

    .footer1Heading{
        font-weight: 500;
        text-align: start;
        /* border: solid red; */
        /* margin-top: 1%; */
        margin-left: 4%;
        padding-right: 0px;
        padding: 0px;
        width: 100%;
        font-size: 90%;
    }
   

/*     Nav right  */


    .heading{
        /* border: solid red; */
        font-size: 15px;
        color: gray;
        width: 80px;
        margin:auto ;
    }
    .hrTag{
        /* display: none; */
        width: 0%;
    }


    .footerright{
         /* border: solid red; */
        display: block;
        margin-left: 0%;
        text-align: center;
    }

    

    .footer2{
        /* border: solid yellow; */
        margin: auto;
        max-width: 443px;
        /* min-width: 443px; */
        text-align: center;
        padding: 4%;
        width: 100%;
        /* display: flex; */
    }
    .footer2 > .hrTag{
        width: 50%;
    }

    .footer2 > .contentDiv{
        font-size: 90%;
    }


    .contentDiv{
       
        font-size: 75%;
    }

    .footer3{
        box-sizing: border-box;
        /* border: solid greenyellow; */
        margin: auto;
        max-width: 443px;
        text-align: center;
        padding: 4%;
        width: 100%;
        display: flex;
    }
    .footer3 > p{
        font-size: 85%;
    }
    .footer4{
        display: none;
    }
}

@media only screen and (min-width: 611px) and (max-width: 1070px) {
    .footer{
        margin: auto;
        max-width: 1800px;
        /* border: solid yellow; */
        box-sizing: border-box;
        color: white;
        text-align: center;
        padding: 30px 10px ;
      
        background-color: #1a191d;
        display: flex;
        justify-content:space-between;
        justify-items: center;
        width: 100%;
        margin-top: 30px;
    }

    .hrTag{
        width: 50%;
    }

    .heading{
        font-size: 15px;
        line-height: 21px;
    }
    .footerright{
        /* border: solid red; */
        text-align: center;
        width: 100%;
        margin-left: 1%;
        display: flex;
        justify-content: space-around;
    }

    .footer1{
        width: 25%;
        color: white;
        padding-right: 20px;
        border-right: solid #3087e5;
        font-size: 16px;
    }
    .contactLinkTag{
        text-decoration: none;
        color: white;
    }
    .contactLinkTag:hover{
        color: #3087e5;
    }
    
    .footer1>div>img{
        border: solid transparent;
        /* width: 100px; */
        width: 50%;
        border-radius: 50%;
       
    }


    /*   footer right */

    .contentDiv{
        font-size: 14px;
    }
    
    
    .footer2{
        /* border: solid yellow; */
        text-align: center;
        /* width: 20%; */
       box-sizing: border-box;
       
    }
    
    .footer3{
        width: 20%;
       
    }
    .footer4{
        width: 19%;
        font-size: 13px;
    }
   
    .linkTag{
        color: white;
        text-decoration: none;
    }
    .linkTag :hover{
        color: #3087e5;
        text-decoration: none;
    }
}