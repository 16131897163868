.pageContent{
    max-width: 1500px;
    background-image: url("../images/wallpapers/wall3.jpg");
    background-position:top;
    background-size: cover;
    
    margin: auto;
    padding-bottom: 100px;
}

.image_with_contactInfo{
  
    width: 96%;
    margin: auto;
    margin-top: 110px;
    text-align: center;
    /* border: solid yellow; */
    display: flex;
    padding-top: 100px;
    justify-content: space-between;
   
}

.contactInfo{
    /* border: solid green; */
    width: 45%;
    margin: auto;
    
}
.contactInfoheading{
    color:#011c46 ;
    padding-bottom: 20px;
    font-family:Arial, Helvetica, sans-serif
}

.contactContentDiv{
/* border: solid red; */
width: 80%;
margin-left: 20%;
}

.contactLine{
    /* border: solid yellow; */
    margin: auto;
    width: 100%;
    display: flex;
    column-gap: 18px;
    margin: auto;
    font-size: 14px;
}
.contactLineSVG{
    /* border: solid red; */
    width:4%;
   padding: 0px;
   
}


.contactImgDiv{
    width: 46%;
    /* margin: auto; */
    /* border: solid red; */
}

.contactImg{
    width: 100%;
    border-radius: 10px;
}

.contactLinkTag{
    text-decoration: none;
    color: #011c46;
    font-size: 110%;
    font-weight: 700;
}

.contactLinkTag:hover{
    color: #3087e5;
}

.contactFormSection{
    margin-top: 150px;
    /* border: solid red; */
    display: flex;
    justify-content: space-between;
}

.contactFormSectionLeft{
    /* border: solid red; */
    width: 40%;
    margin: auto;
    text-align: center;
    margin-top: 10%;
}
.contactFormSectionLeft>div>h1{
    font-family: serif
}

.categoryBtn{
    margin-top: 100px;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 700;
    background-color: #011c46;
    border: solid transparent;
    color: white;
    border-radius: 5px;
    font-family:Arial, Helvetica, sans-serif;
}

.categoryBtn:hover{
    cursor: pointer;
    background-color: #3087e5;
    transition: 0.5s;
}

.contactFormSectionRight{
    width: 50%;
    margin-left: 4%;
}


@media only screen and (min-width: 501px) and (max-width: 800px){


    .pageContent{
        max-width: 1500px;
        background-image: url("../images/wallpapers/wall3.jpg");
        background-position:top;
        background-size: cover;
        
        margin: auto;
        padding-bottom: 100px;
    }
    
    .image_with_contactInfo{
      
        width: 99%;
        margin: auto;
        margin-top: 110px;
        text-align: center;
        /* border: solid yellow; */
        display: flex;
        padding-top: 100px;
        justify-content: space-between;
       
    }
    
    .contactInfo{
        width: 55%;
        margin: auto;
        
    }
    .contactInfoheading{
        font-size: 18px;
        color:#011c46 ;
        padding-bottom: 20px;
        font-family:Arial, Helvetica, sans-serif
    }

    .contactContentDiv{
        /* border: solid red; */
        width: 80%;
        margin-left: 20%;

        }
    
    .contactLine{
        /* border: solid red; */
        margin: auto;

        width: 100%;
        display: flex;
        column-gap: 10px;
        font-size: 12px;
        
    }
    .contactLineSVG{
        /* border: solid red; */
        width:4%;
       padding: 0px;
       
    }
    
    
    .contactImgDiv{
        width: 40%;
        /* margin: auto; */
        /* border: solid red; */
    }
    
    .contactImg{
        width: 100%;
        border-radius: 10px;
    }
    
    .contactLinkTag{
        text-decoration: none;
        color: #011c46;
        font-size: 110%;
        font-weight: 700;
    }
    
    .contactLinkTag:hover{
        color: #3087e5;
    }
    
    .contactFormSection{
        margin-top: 150px;
        /* border: solid red; */
        display: flex;
        justify-content: space-between;
    }
    
    .contactFormSectionLeft{
        /* border: solid red; */
        width: 40%;
        margin: auto;
        text-align: center;
        margin-top: 10%;
    }
    .contactFormSectionLeft>div>h1{
        font-family: serif;
        font-size: 24px;
    }
    
    .categoryBtn{
        margin-top: 100px;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 700;
        background-color: #011c46;
        border: solid transparent;
        color: white;
        border-radius: 5px;
        font-family:Arial, Helvetica, sans-serif;
    }
    
    .categoryBtn:hover{
        cursor: pointer;
        background-color: #3087e5;
        transition: 0.5s;
    }
    
    .contactFormSectionRight{
        width: 55%;
        margin-left: 2%;
    }
    

}

@media only screen and (min-width: 100px) and (max-width: 500px){


    .pageContent{
        max-width: 1500px;
        background-image: url("../images/wallpapers/wall3.jpg");
        background-position:top;
        background-size: cover;
        
        margin: auto;
        padding-bottom: 100px;
    }
    
    .image_with_contactInfo{
      
        width: 99%;
        margin: auto;
        margin-top: 100px;
        text-align: center;
        /* border: solid yellow; */
        display: block;
        padding-top: 0px;
        justify-content: space-between;
       
    }
    
    .contactInfo{
        border: solid transparent;
        width: 99%;
        max-width: 250px;
        margin: auto;
        margin-bottom: 40px;
    }
    .contactInfoheading{
        font-size: 16px;
        color:#011c46 ;
        padding-bottom: 20px;
        font-family:Arial, Helvetica, sans-serif
    }

    .contactContentDiv{
        /* border: solid red; */
        width: 95%;
        margin-left: 8%;

        }
    
    .contactLine{
        /* border: solid red; */
        margin: auto;
        text-align: center;
        width: 100%;
        display: flex;
        column-gap: 10px;
        font-size: 11px;
        
    }
    .contactLineSVG{
        /* border: solid red; */
        width:5%;
       padding: 0px;
       
    }
    
    
    .contactImgDiv{
        width: 100%;
        margin: auto;
        /* border: solid red; */
    }
    
    .contactImg{
        width: 100%;
        border-radius: 0px;
    }
    
    .contactLinkTag{
        text-decoration: none;
        color: #011c46;
        font-size: 110%;
        font-weight: 700;
    }
    
    .contactLinkTag:hover{
        color: #3087e5;
    }
    
    .contactFormSection{
        margin-top: 80px;
        /* border: solid red; */
        display: flex;
        justify-content: space-between;
    }
    
    .contactFormSectionLeft{
        display: none;
        /* border: solid red; */
        width: 40%;
        margin: auto;
        text-align: center;
        margin-top: 10%;
    }
    .contactFormSectionLeft>div>h1{
        font-family: serif;
        font-size: 24px;
    }
    
    .categoryBtn{
        margin-top: 100px;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 700;
        background-color: #011c46;
        border: solid transparent;
        color: white;
        border-radius: 5px;
        font-family:Arial, Helvetica, sans-serif;
    }
    
    .categoryBtn:hover{
        cursor: pointer;
        background-color: #3087e5;
        transition: 0.5s;
    }
    
    .contactFormSectionRight{
        width: 98%;
        
        margin: auto;
    }
    

}
