.faqheading{
color: #007aff;
text-align: center;
line-height: 0px;
}
.faq{
    /* border: solid green; */
   padding: 0px 20px;
   background-color: rgb(222, 222, 222);
}
.faq:first-child{
    margin: auto;
}

.faq > div{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 20px;
    background-color:white ;
    width:100%
}

.faq > div > details > summary{
    padding: 10px 20px;
    line-height: 70px;
    font-size: 20px;
}
.faq > div > details > summary:hover{
    cursor: pointer;
    color:#007aff ;
}

@media(max-width:998px) {

    

}
