
.body{
    margin: auto;
    margin-top: 150px;
    width:70%;
    max-width: 1600px;
    box-sizing: border-box;
}

.product{
    box-sizing: border-box;
    /* border: solid red; */
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    height: 300px;
}

.imgdiv{
    height: 100%;
    width: 35%;
}
.imgdivImg{
    width: 100%;
    height: 100%;
}
.rightProductNameLinkTag{
    color: black;
    width: 40%;
    display: block;  
    text-decoration:none;
}
.rightContactBtn{
    border: solid transparent;
    color: white;
    background-color: #00438b;
    padding: 6px 20px;
    margin-top: 10px;
    font-size: 15px;
    width: 150px;
    border-radius: 6px;   
}
.rightContactBtn:hover{
    cursor: pointer;
    transition: 0.5s;
    background-color:#011c46
}

.right{
    /* border: solid yellow; */
    width: 55%;
}

@media only screen and (min-width: 100px) and (max-width: 500px){
    .body{
        margin: auto;
        margin-top: 130px;
        width:90%;
    }
    .product{
        /* border: solid red; */
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        height: 130px;
    }
    .imgdiv{
        width: 45%;
    }
    .right{
        /* border: solid yellow; */
        width: 47%;
        font-size: 10px;
    }
    .rightContactBtn{
        border: solid transparent;
        color: white;
        background-color: #00438b;
        padding: 6px 8px;
        margin-top: 2px;
        font-size: 11px;
        width: 60%;
        border-radius: 6px;   
    }
}