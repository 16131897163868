/* .imagess{
    width: 100%;
    height: 400px;
} */

.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    min-width: 100%;
    box-sizing: border-box;
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .carousel-control:first-child {
    left: 10px;
  }
  
  .carousel-control:last-child {
    right: 10px;
  }
  