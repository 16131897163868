.body{
    margin: auto;
    margin-top: 150px;
    width:70%;
}
.topicsHeadings{
    background-color: #00438b;
    color: white;
    padding: 30px;
}

@media only screen and (min-width: 100px) and (max-width: 500px) {

.body{
    width: 90%;
    margin-top: 100px;
}
.topicsHeadings{
    font-size: 17px;
    padding:10px;
}
}