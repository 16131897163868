
.body{
    margin: auto;
    margin-top: 150px;
    width:70%;
}

.sections1{
    display: flex;
    width: 100%;
}

.sections2{
    margin-top: 20px;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}


.section_left{
    /* width: 30%; */
}
.section_left>img{

    width: 90%;
    height: 100%;
}
.sections_link{
    width: 30%;
    display: block;  
    text-decoration:none;
}
.name_link{
    text-decoration:none;
    color: black;
}
.view_btn_link{
   text-decoration: none;
   color: white;
}
.view_btn{
    border: solid transparent;
    color: white;
    background-color: #00438b;
    padding: 4px 8px;
    margin-top: 2px;
    font-size: 15px;
    width: 130px;
    border-radius: 6px;
}
.view_btn:hover{
    cursor: pointer;
    background-color: #216dbf;
}

@media only screen and (min-width: 200px) and (max-width: 800px) {

.body{
    margin-top: 120px;
}
.sections_link{
    /* border:  solid yellow; */
    text-decoration:none;
    color: black;
    width: 80%;
    margin: auto;
}
.sections{
    padding-bottom:35px ;
}

.name_link{
    line-height: 1px
}

.sections1{
    /* border: solid red; */
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.sections2{
    text-align: center;
    margin-top: 20px;
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.sections_link div{
    display: block;  
    text-decoration:none;
}

.view_btn{
    margin-top: 0px;
}

}