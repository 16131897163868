.below_nav_body{
    margin: auto;
    margin-top: 150px;
    width:70%;
}

.section1{
    /* border: solid green; */
    display: flex;
    justify-content: space-between;
}

.section1_left{
    width: 50%;
}

.section1_left>img{
    /* border: solid red; */
    width: 100%;
}

.section1_right{
    /* border: solid blue; */
    width: 45%;
    margin-right: 1%;
    /* text-align: center; */
}

.section1_right>div:first-child{
    color: #007aff;
    
}

.productDetailsBTN{
    border: solid transparent;
    background-color: #007aff;
    color: white;
    cursor: pointer;
    width: 70%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.productDetailsBTN:hover{   
    background-color: #54a0f0;
}
.section2{
    margin-top: 20px;
}
.section2>hr+h2{
    text-decoration:underline
}


/* @media(max-width:998px) {


    .below_nav_body{
        margin: auto;
        margin-top: 150px;
        width:90%;
    }

    .section1{
        /* border: solid green; */
        /* display: block;
        
    }

    .productDetailsBTN{
        border: solid transparent;
        background-color: #007aff;
        color: white;
        cursor: pointer;
        width: 70%;
        border-radius: 10px;
        margin-bottom: 10px;
    }

} */ 

@media(max-width:998px) {
    .below_nav_body{
        margin: auto;
        margin-top: 150px;
        width:90%;
    }

    .section1{
        /* border: solid green; */
        display: block;
        
    }

    .section1_left{
        margin: auto;
        width: 90%;
    }

    .section1_right{
        /* border: solid blue; */
        width: 90%;
        margin-right: 1%;
        text-align: center;
        margin: auto;
    }



}