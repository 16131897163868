.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* ====================================== for  corosole or image slider ============= */

.image-slider {
  margin: auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
  
}
.slider-image-inner-div{
  display: flex;
  width: 100%;
}
.slider-image-text-div{
position: absolute;
top: 30%;
width: 100%;
height: 20%;
backdrop-filter: blur(5px); /* Adjust the blur value as needed */
display: flex;
align-items: center;
justify-content: center;
color: white; /* Adjust text color */
}

.slider-images {
  /* height: 100%; */
  height: fit-content;
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-image {
  height: 100%;
  flex: 0 0 100%;
  box-sizing: border-box;
}

.slider-image img {
  width: 100%;
  /* height: fit-content; */
  height: auto;
  height: 400px;
}

.image-slider-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.image-slider-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.image-slider-dot.active {
  background-color: #555;

}

@media only screen and (min-width: 100px) and (max-width: 500px) {
  
  .image-slider {
    
    box-sizing: border-box;
    margin: auto;
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 200px;
    margin-top: 1px;
  }
}


/* ==================================================== */


















@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
