
/* .contactForm input[required]:invalid{
    border: 1px solid #dc3545;
} */



.contact{
    box-sizing: border-box;
    border: solid transparent;
    line-height: 0px;
    width:100% ;
    text-align: center;
    /* background-image: url("../images/wallpapers/wall3.jpg"); */
    background-position:top;
    background-size: cover;
    padding-top: 5% ;
    padding-bottom: 10%;
    background-color: rgba(255,255,255,0.5);
    border-radius: 5px;
}

input:focus {
    /* outline: none; */
    outline-color: rgb(133, 126, 126);
}

.contactForm{
    width: 70%;
    text-align: center;
    margin: auto;  
}

.contactForm input:nth-child(1){
    border:solid transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 100%;
    height: 35px;
    font-size: 18px;
    align-items: center;
   margin-top: 50px;
   margin-bottom: 10px;
  
}
.contactForm input:nth-child(3){

    border: solid transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 100%;
    height: 35px;
    font-size: 18px;
    align-items: center;
    margin: 10px auto;
}
.contactForm input:nth-child(5){
    border: transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 100%;
    height: 35px;
    font-size: 18px;
    align-items: center;
    margin: 10px auto;
  
   
}
/* .contactForm input:nth-child(5):focus{
    -moz-appearance: textfield;
} */

/* ===============================================   For removig increase and decrease buttons */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.contactForm input:nth-child(7){

    border: transparent;
    border-bottom: solid #00438b;
    padding: 5px 15px;
    width: 100%;
    height: 35px;
    font-size: 18px;
    align-items: center;
    margin: 10px auto;
}

.message{
    resize: none;
    border: transparent #00438b;
    border-bottom: solid #00438b;
    margin-top: 10px;
    font-size: 18px;
    padding: 5px 15px;
    width: 100%;
    height:200px;
    text-align: left;
}

.contactbtn{
    border: solid transparent;
    border-bottom: solid black;
    background-color: #3087e5;
    color: white;
    padding: 6px 20px;
    margin-top: 20px;
    font-size: 20px;
    width: 150px;
    border-radius: 10px;   
}
.contactbtn:hover{
    transition: 0.7s;
    color: white;
    background-color: #00438b;
    border-bottom:solid #00438b;
    cursor: pointer;
}

@media only screen and (min-width: 601px) and (max-width: 800px)  {

    .contact{
        
        box-sizing: border-box;
        border: solid transparent;
        /* border: solid yellow;  */
        line-height: 0px;
        width:100% ;
        text-align: center;
       
       
        background-position:top;
        background-size: cover;
        padding: 10% 0%;
    }
    .contact>h1{
        font-size: 20px;
        margin-bottom: 30px;
    }
    .contactForm input:nth-child(1){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 15px;    
    }
    
    .contactForm input:nth-child(3){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 15px; 
    }

    .contactForm input:nth-child(5){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 15px; 
    }
    .contactForm input:nth-child(7){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 15px; 
    }
    .message{
        padding: 5px 15px;
        
        font-size: 15px;
        width: 90%;
        height:160px;
    }

    .contactbtn{
        border: solid transparent;
        border-bottom: solid black;
        background-color: #3087e5;
        color: white;
        padding: 6px 18px;
        margin-top: 20px;
        font-size: 15px;
        width: 130px;
        border-radius: 10px;   
    }
    .contactbtn:hover{
        transition: 0.7s;
        color: white;
        background-color: #00438b;
        border-bottom:solid #00438b;
        cursor: pointer;
    }
}

@media only screen and (min-width: 100px) and (max-width: 600px){
    .contact{
        border: solid;
        box-sizing: border-box;
        border: solid transparent;
        /* border: solid yellow;  */
        line-height: 0px;
        width:100% ;
        text-align: center;
        
        background-position:top;
        background-size: cover;
        padding: 10% 0%;
       
    }
    .contact>h1{
        font-size: 20px;
        margin-bottom: 30px;
    }
    .contactForm input:nth-child(1){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 14px;    
    }
    
    .contactForm input:nth-child(3){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 14px;  
    }

    .contactForm input:nth-child(5){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 14px;  
    }
    .contactForm input:nth-child(7){
        padding: 5px 5px;
        width: 100%;
        height: 18px;
        font-size: 14px;  
    }
    .message{
        padding: 5px 15px;
        
        font-size: 14px;
        width: 88%;
        height:150px;
    }


    .contactbtn{
        border: solid transparent;
        border-bottom: solid black;
        background-color: #3087e5;
        color: white;
        padding: 6px 10px;
        margin-top: 20px;
        font-size: 15px;
        width: 120px;
        border-radius: 10px;   
    }
    .contactbtn:hover{
        transition: 0.7s;
        color: white;
        background-color: #00438b;
        border-bottom:solid #00438b;
        cursor: pointer;
    }
}
