/* Alert.css */
.alertTrue {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: auto;
  background-color:  red;
  color: white;
  width: 16%;
  border-radius: 10px;
  padding: 0.2% 1%;
  position:fixed;
  z-index: 1000;
  top: 16%;
  left: 50%;
  transform:translate(-50%,-50%);
  margin: auto;
  text-align: center;
  transition: all 0.4s;
  
  }
.alertFalse{
  top: -100%;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: auto;
  background-color:  red;
  color: white;
  width: 14%;
  border-radius: 10px;
  padding: 0.2% 1%;
  position:fixed;
  z-index: 1000;
  left: 50%;
  transform:translate(-50%,-50%);
  margin: auto;
  text-align: center;
  transition: all 2s;
}

@media only screen and (min-width: 100px) and (max-width: 500px)  {
  .alertTrue {
    width: 50%;
    font-size: 13px;

  }
}
  
 