
.back{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(189, 189, 189,0.5);
    
}
.model{
    
    font-family:serif;
    margin: auto;
    background-color:  white;
    width: 60%;
    max-width: 1100px;
    border-radius: 10px;
    padding: 2% 0%;
    position:fixed;
    top: 53%;
    left: 50%;
    transform:translate(-50%,-50%);
    margin: auto;
    text-align: center;
}



.modelForm{
    font-family: serif;
    font-size: 40px;
}

.modelFormName{
   
    border: transparent;
    border-bottom: solid grey;
    padding: 4px 15px;
    width: 70%;
    height: 17px;
    font-size: 17px;
    align-items: center;
    margin-bottom:3px;
   
}
.modelForm input:nth-child(3){

    border: transparent;
    border-bottom: solid grey;
    padding: 4px 15px;
    width: 70%;
    height: 17px;
    font-size: 18px;
    align-items: center;
    margin: 10px auto;
}
.modelForm input:nth-child(5){
    border: transparent;
    border-bottom: solid grey;
    padding: 4px 15px;
    width: 70%;
    height: 17px;
    font-size: 18px;
    align-items: center;
    margin: 10px auto;
}



.inqueryProductDiv{
    /* border: solid red; */
    margin: auto;
    width: 60%;
    text-align: center;
    display: flex;
    align-items: center;
    
}
.inquery{
    margin-left: 5%;
    /* border: solid green; */
    font-size:19px;
    color:gray;
    text-align: center;
}

.inqueryProduct{
    margin: auto;
    padding-top: 3px;
   /* border: solid blue; */
   font-size:19px;
   color:#00438b;
   margin-left: 5%;
}

.modelSelect{
    padding: 1% 5%;
    margin-top: 10px;
    font-size: 18px;
    margin-right: 45%;
    line-height: 0px;
}
.modelMsg{
    font-family: "Ubuntu", sans-serif;
    padding: 8px;
    font-size: 17px;
    height: 95px;
    width: 75%;
    resize: none;
    margin-top: 17px;
}
.modelMsg:focus{ 
    /* border: solid #00438b;  */
}

.popupBtn{
    border: solid transparent;
    margin-top: 10px;
    background-color: black;
    color: white;
    font-size: 18px;
    padding: 5px 30px;
    border-radius: 5px;
}
.popupBtn:hover{
   cursor: pointer;
}

.cancelBtn{
    border: solid transparent;
    margin-left: 4%;
    margin-top: 10px;
    background-color: red;
    color: white;
    font-size: 18px;
    padding: 5px 25px;
    border-radius: 5px;
}

.cancelBtn:hover{
    cursor: pointer;
 }


 .ThankYouOkBtn{
    cursor: pointer;
    border: solid transparent;
    margin-left: 4%;
    margin-top: 10px;
    background-color: black;
    color: white;
    font-size: 18px;
    padding: 8px 45px;
    border-radius: 5px;
 }




.loader {
    
    margin: auto;
    margin-top: 1%;
    width: 40px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #00438b;
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}


 
.wrapper{
    min-height: 300px;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    /* background-color:#eee */
}
.checkmark__circle{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}
.checkmark{
    /* width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 5; /* You might want to increase stroke-width proportionally */
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}
.checkmark__check{
    /* transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards */
    transform-origin: 50% 50%;
    stroke-dasharray: 60; /* Adjust this value */
    stroke-dashoffset: 60; /* Adjust this value */
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke{
    100%{stroke-dashoffset: 0}
}
@keyframes scale
{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}
}
@keyframes fill{100%{box-shadow: inset 0px 0px 0px 50px #7ac142}}


@media only screen and (min-width: 501px) and (max-width: 1000px)  {
    .model{
    
        font-family:serif;
        margin: auto;
        background-color:  white;
        width: 80%;
        max-width: 1100px;
        border-radius: 10px;
        padding: 2% 0%;
        position:fixed;
        top: 56%;
        left: 50%;
        transform:translate(-50%,-50%);
        margin: auto;
        text-align: center;
    }
}


@media only screen and (min-width: 100px) and (max-width: 500px)  {

    .model{
        font-size: 70%;
        width: 83%;
        padding: 5% 2%;
    }
    .modelForm input:nth-child(1){

        border: transparent;
        border-bottom: solid grey;
        padding: 1px 4px;
        width: 80%;
        height: 14px;
        font-size: 15px;
        align-items: center;
        margin: 1px auto;
    }
    .modelForm input:nth-child(3){

        border: transparent;
        border-bottom: solid grey;
        padding: 1px 4px;
        width: 80%;
        height: 14px;
        font-size: 15px;
        align-items: center;
        margin-top: 1px;
    }
    .modelForm input:nth-child(5){

        border: transparent;
        border-bottom: solid grey;
        padding: 1px 4px;
        width: 80%;
        height: 11px;
        font-size: 15px;
        align-items: center;
        margin-top: 1px;
    }

   
    .modelSelect{
        font-family: sans-serif;
        font-size: 12px;
        margin-right: 0%;
    }
  
    .modelMsg{
        width: 90%;
        font-family: sans-serif;
        padding: 1px;
        font-size: 15px;
    }
    .inqueryProductDiv{
        /* border: solid red; */
        margin: auto;
        width: 90%;
        text-align: center;
        display: flex;
      
    }

    .inquery{
        font-size: 14px;
    }

    .inqueryProduct{
        /* line-height: 2px; */
        font-size: 14px;
        /* margin-left: 1px; */
    }

    .popupBtn{
        font-size: 15px;
    }
    .cancelBtn{
        font-size: 15px;
    }
    .ThankYouOkBtn{
        margin-top: 7px;
        font-size: 13px;
        padding: 5px 35px;
    }
}